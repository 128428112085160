import PropTypes from "prop-types";

import { Title, Section, Container, HTML, getProperty } from "@gh/shared";

export const Content = ({ data }) => {
  const title = getProperty(data, "title");
  const content = getProperty(data, "content");

  return (
    <Section size="md">
      <Container>
        <Title wrapper="h1">{title}</Title>
        <HTML content={content} />
      </Container>
    </Section>
  );
};

Content.defaultProps = {
  data: {},
};

Content.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
};

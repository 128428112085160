import { Fragment } from "react";
import { graphql } from "gatsby";

import { getProperty } from "@gh/shared";

import { Meta } from "./common/meta";
import { Content } from "./privacy/content";
// import { Cookies } from "./privacy/cookies";

const Template = ({ data }) => {
  const content = {
    title: getProperty(data, "markdownRemark.frontmatter.title"),
    content: getProperty(data, "markdownRemark.html"),
  };
  // const cookies = getProperty(data, "markdownRemark.frontmatter.privacy.cookies");
  const meta = getProperty(data, "markdownRemark.frontmatter.meta");

  return (
    <Fragment>
      <Meta data={meta} />
      <Content data={content} />
      {/* <Cookies data={cookies} /> */}
    </Fragment>
  );
};

export default Template;

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        path
        title
        meta {
          title
          description
        }
      }
      html
    }
  }
`;
